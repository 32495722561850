import { createSelector, createSlice } from "@reduxjs/toolkit";
import { store } from "../store";


const initialState = {
    data: {},
    contestLeaderboarddata: {},
    playwithfrienddata:{}
};

export const tempSlice = createSlice({
    name: "Tempdata",
    initialState,
    reducers: {
        tempdataSuccess: (temporary, action) => {
            temporary.data = action.payload.data;
        },
        contestLeaderboarddataSuccess: (temporary, action) => {
            temporary.contestLeaderboarddata = action.payload.data
        },
        playwithFrienddataSuccess: (temporary, action) => {
            temporary.playwithfrienddata = action.payload.data
        },
    }
});

export const {tempdataSuccess,contestLeaderboarddataSuccess,playwithFrienddataSuccess} = tempSlice.actions;
export default tempSlice.reducer;

export const Loadtempdata = (data) => {
    store.dispatch(tempdataSuccess({data}))
}

export const LoadcontestLeaderboard = (data) => {
    store.dispatch(contestLeaderboarddataSuccess({data}))
}

export const playwithFrienddata = (data) => {
    store.dispatch(playwithFrienddataSuccess({ data }));
}

// selector
export const selecttempdata = createSelector(
    state => state.Tempdata,
    Tempdata => Tempdata.data,
)

export const contestleaderboard = createSelector(
    state => state.Tempdata,
    Tempdata => Tempdata.contestLeaderboarddata,
)

export const playwithfreind = createSelector(
    state => state.Tempdata,
    Tempdata => Tempdata.playwithfrienddata,
)