import React, { lazy, Suspense, useEffect, useState} from "react";
import { I18nextProvider } from "react-i18next";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavScrollTop from "./components/NavScrollTop";
import language from "./utils/language";
import config from "./utils/config";
import { settingsLoaded, sysConfigdata, systemconfigApi } from "./store/reducers/settingsSlice";
import { useSelector } from "react-redux";
import { logout } from "./store/reducers/userSlice";
import { auth } from "./firebase";
import { Route, Routes, useNavigate } from 'react-router-dom';
import Router from "./routes/Router";
import TopHeader from "./components/smalltopheader/TopHeader";
import Header from "./partials/header/Header";
import Footer from "./partials/footer/Footer";
// import AdSense from "./components/adsense/Adsense";

// CSS File Here
import "antd/dist/antd.min.css";
import "./assets/css/fonts/fonts.css";
import "./assets/css/vendor/animate.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'

//for LTR
import "./assets/css/bootstrap.min.css";
import "./assets/scss/style.scss";

// for RTL
// import "./assets/css/bootstrap.rtl.min.css";
// import "./assets/css/style.rtl.css";

// Maintenance Mode
const Maintainance = lazy(() => import("./pages/Maintainance"));

const MySwal = withReactContent(Swal);

const App = () => {
    const navigate = useNavigate();

    const [redirect, setRedirect] = useState(false);

    // if same id login in other brower then its logout from current session
    axios.interceptors.response.use(function (response) {
        if (!config.demo && response.data && response.data.message === "129") {
            MySwal.fire({
                text: "Already logged in other device",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Logout",
            }).then((result) => {
                if (result.isConfirmed) {
                    logout()
                    auth.signOut();
                    navigate('/');
                    return false;
                }
            });
        }
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    });

    // all settings data
    useEffect(() => {
        settingsLoaded("");
        systemconfigApi((success) => {

        }, (error) => {
            console.log(error);
        });
    }, [])

    // Maintainance Mode
    const getsysData = useSelector(sysConfigdata)

    useEffect(() => {
        if (getsysData && getsysData.app_maintenance === "1") {
            setRedirect(true)
        } else {
            setRedirect(false)
        }
    }, [getsysData.app_maintenance]);

    return (
        <I18nextProvider i18n={language}>
            <ToastContainer />
            {/* <AdSense /> */}
            <TopHeader />
            <Header />
            <NavScrollTop>
                {redirect ?
                    <Routes>
                        <Route path="*" exact={true} element={<Maintainance />}/>
                    </Routes>
                    :
                    <Suspense>
                        <Router/>
                    </Suspense>
                }
            </NavScrollTop>
            <Footer />
        </I18nextProvider>
    );
};
export default App;
